// import React, { useState } from 'react';



// import ProspectForm from './CustomForm/customform';
// function App() {
//   // const [username, setUsername] = useState(null);

  
//   return (
//     <div>
//      <ProspectForm/>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import LeadInformationForm from './Lead Information/leadinformation';
import './App.css'
const App = () => {
  return (
    <div>
      <LeadInformationForm />
    </div>
  );
};

export default App;

